<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <div class="col-12">
          <h5>Add Product</h5>
          <div class="p-fluid formgrid grid">
            <div class="field col-12 md:col-6">
              <label for="product-name">Product Name</label>
              <InputText v-model="form.name" id="product-name" type="text" />
            </div>
            <div class="field col-12 md:col-6">
              <label for="weight-desc">Weight (KG)</label>
              <InputNumber v-model="form.weight" showButtons mode="decimal"></InputNumber>
            </div>
            <div class="field col-12 md:col-6" style="display: flex; align-items: center; gap: 1rem">
              <div class="" style="display: flex; align-items: center; gap: 1rem">
                <label for="weight-desc">Pre-order ?</label>
              </div>
              <InputSwitch v-model="form.is_pre_order" />
            </div>
            <div v-if="form.is_pre_order" class="field col-12 md:col-6">
              <label for="weight-desc">Pre-order Day</label>
              <InputNumber v-model="form.pre_order_day" showButtons mode="decimal"></InputNumber>
            </div>
            <div class="field col-12">
              <label for="product-description">Product Description</label>
              <Textarea v-model="form.description" id="product-description" rows="5" type="text" />
            </div>
            <div class="field col-12">
              <label for="product-select">Product Attributes</label>
              <MultiSelect
                v-model="multiselectValue"
                :options="multiselectValues"
                optionLabel="name"
                placeholder="Select Attributes"
                :filter="true"
              >
                <template #value="slotProps">
                  <div
                    class="inline-flex align-items-center py-1 px-2 bg-primary text-primary border-round mr-2"
                    v-for="option of slotProps.value"
                    :key="option.id"
                  >
                    <div>{{ option.name }}</div>
                  </div>
                  <template v-if="!slotProps.value || slotProps.value.length === 0">
                    <div class="p-1">Select Attributes</div>
                  </template>
                </template>
                <template #option="slotProps">
                  <div class="flex align-items-center">
                    <div>{{ slotProps.option.name }}</div>
                  </div>
                </template>
              </MultiSelect>
            </div>
            <div class="field col-12" style="display: flex; align-items: center; gap: 1rem">
              <div class="" style="display: flex; align-items: center; gap: 1rem">
                <label for="weight-desc">Disable Add to cart ?</label>
              </div>
              <InputSwitch v-model="form.disabled_add_cart" />
            </div>
            <div class="field col-12">
              <label for="product-description">Disable Add to cart Message</label>
              <ckeditor :editor="editor" v-model="form.message_disabled" :config="editorConfig"></ckeditor>
            </div>

            <div class="field col-12 md:col-6" style="display: flex; align-items: center; gap: 1rem">
              <div class="" style="display: flex; align-items: center; gap: 1rem">
                <label for="weight-desc">Big Stuff ?</label>
              </div>
              <InputSwitch v-model="form.is_big_stuff" />
            </div>

            <div class="field col-12" style="display: flex; align-items: center; gap: 1rem">
              <div class="" style="display: flex; align-items: center; gap: 1rem">
                <label for="weight-desc">Active ?</label>
              </div>
              <InputSwitch v-model="form.is_active" />
            </div>
            <div class="field col-12 md:col-4">
              <input @change="fileHandler" type="file" />
              <div class="preview-image">
                <img v-if="preview" class="mt-4" width="200" :src="preview" alt="" />
                <button
                  v-if="preview"
                  @click="
                    () => {
                      file = null;
                      preview = null;
                    }
                  "
                >
                  x
                </button>
              </div>
            </div>
          </div>
          <Button
            :loading="loading"
            v-if="!$route.query.id"
            @click="createProduct"
            label="Create Product"
            class="mr-2 mb-2 mt-4"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AttributesApi from "../../api/Attribute";
import BaseURL from "../../api/BaseURL";
import ProductApi from "../../api/Product";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {},
      baseUrl: BaseURL,
      showModalSaveAll: false,
      form: {
        name: "",
        weight: null,
        is_pre_order: false,
        pre_order_day: 0,
        description: "",
        product_attributes: [],
        is_use_variant: false,
        is_active: false,
        is_big_stuff: false,
        categories: [],
        collections: [],
      },
      file: null,
      attribute_list: [],
      preview: null,
      loading: false,
      multiselectValue: null,
      multiselectValues: [],
      upload_status: false,
    };
  },
  mounted() {
    this.getAttributes();
  },

  methods: {
    async getAttributes() {
      try {
        const response = await AttributesApi.Get();
        if (response.data.status === 200) {
          let attributes = [];
          response.data.data.map((data) => {
            attributes.push({ name: data.attr_name, id: data._id });
          });
          this.multiselectValues = attributes;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async createProduct() {
      try {
        if (!this.multiselectValue?.length) {
          alert("Please Add min 1 step");
          return;
        }

        this.loading = true;
        let attributes_id = [];
        this.multiselectValue.map((attr) => attributes_id.push(attr.id));

        let form = { ...this.form, product_attributes: [...attributes_id] };
        const response = await ProductApi.Add(form);

        const formData = new FormData();

        if (this.file) {
          formData.append("file", this.file);
          formData.append("file_mobile", this.file);
          const uploadImage = await ProductApi.UploadImage(response.data.data._id, formData);
          if (uploadImage.data.status === 200) {
            this.upload_status = true;
          }
        }
        if (response.data.status === 200 && this.upload_status) {
          this.loading = false;
          this.$toast.add({
            severity: "success",
            summary: "Product Created!",
            detail: "Successfully Created Product!",
            life: 4000,
          });
          this.$router.push("/admin/product-management/product");
        } else {
          this.loading = true;
          this.$toast.add({
            severity: "error",
            summary: "Failed",
            detail: "Failed to create Product!",
            life: 4000,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    fileHandler(e) {
      this.file = e.target.files[0];
      this.preview = URL.createObjectURL(this.file);
    },
  },
};
</script>

<style lang="scss" scoped>
.preview-image {
  position: relative;
  button {
    position: absolute !important;
    top: 20px;
    left: 0;
    z-index: 20;
  }
}
</style>